require('./bootstrap');

function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("animated");
        }
    }
}
window.addEventListener("scroll", reveal);

function onload() {
    var loads = document.querySelectorAll(".onload");

    for (var i = 0; i < loads.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = loads[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
            loads[i].classList.add("animated");
        }
    }
}
window.addEventListener("pageshow", onload);
